import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Checkbox } from '@material-tailwind/react';

import downloadBaselineReviewPowerpoint from './BaselineReviewDownload';
import LoadingSpinner from './LoadingSpinner';
import downloadLocationMonthlyReviewPowerpoint from './LocationMonthlyReviewDownload';
import SearchBar from '../Common/SearchBar';
import AppAlert from '../CompanyComponents/alerts/Alert';
import CompanyContext from '../../context/CompanyContext';
import {
  CompanyLocationOption,
  CompanyService,
  LocationService,
  RestaurantService,
  Service,
} from '../../interfaces';

const CALCULATOR_CONNECTION_ERROR = 'CalculatorConnectionError';

/**
 * This function retrieves the date of the previous month's first and last dates to be used as default values of datepicker
 * @returns Object - Object containing first date and last date of previous month
 * @returns Object.firstDateOfPrevMonth - first date of previous month
 * @returns Object.lastDateOfPrevMonth - last date of previous month
 */
const getFirstAndLastDateOfPrevMonth = () => {
  const currentDate = new Date();
  const firstDateOfPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const lastDateOfPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  return {
    firstDateOfPrevMonth: new DateObject(firstDateOfPrevMonth),
    lastDateOfPrevMonth: new DateObject(lastDateOfPrevMonth),
  };
};

const ERROR_MESSAGE = {
  noDataInDateRange: 'Specified date range has no report data',
  fetchReportError: 'Error fetching report data from database',
  noImageInDateRange: 'Specified date range has no images',
  fetchImageError: 'Error fetching images from database',
  fetchRestaurantError: 'Error fetching property data',
  fetchLocationMonthlyReviewError: 'Error fetching location review report',
  fetchLocationMonthlyReviewWithCalculatorConnectionError:
    'Connection error. Please try again a few minutes later.',
  fetchBaselineReviewError: 'Error fetching baseline review report',
};

const IS_TAGGED_VALUES: { [key: string]: boolean | string | undefined } = {
  all: undefined,
  allExceptUntagged: 'allExceptUntagged',
  taggedAndResolvable: 'taggedAndResolvable',
  taggedAndMapped: true,
  untagged: false,
};

const WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const QUICK_SELECT_OPTION = {
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  last3Months: 'Last 3 Months',
  fromLocationEarliestStartDate: 'From Location Earliest Start Date',
  fromLocationEarliestProjectStartDate: 'From Location Earliest Project Start Date',
};

const ReportInputCard = () => {
  const companyContext = useContext(CompanyContext);
  const { firstDateOfPrevMonth, lastDateOfPrevMonth } = getFirstAndLastDateOfPrevMonth();
  const { user } = useAuth0();

  const initialFormState: {
    companyField: string;
    restaurantField: string;
    locationField: string;
    arrSelectedService: Array<Service>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
  } = {
    companyField: 'Select Group',
    restaurantField: 'Select Property',
    locationField: 'Select Location',
    arrSelectedService: [],
    dateRange: [firstDateOfPrevMonth, lastDateOfPrevMonth],
    isTagged: IS_TAGGED_VALUES.taggedAndResolvable,
  };

  const initialError: {
    isVisible: boolean;
    message: string;
  } = {
    isVisible: false,
    message: '',
  };

  const [alertHeader, setAlertHeader] = useState('');
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [arrRestaurantService, setArrRestaurantService] = useState<Array<RestaurantService>>([]);
  const [error, setError] = useState(initialError);
  const [formState, setFormState] = useState(initialFormState);
  const [isAlert, setIsAlert] = useState(false);
  const [isCompanyReportLoading, setIsCompanyReportLoading] = useState<boolean>(false);
  const [isRestaurantReportLoading, setIsRestaurantReportLoading] = useState<boolean>(false);
  const [isLocationReportLoading, setIsLocationReportLoading] = useState<boolean>(false);
  const [isCompanyDailyWasteReportLoading, setIsCompanyDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isRestaurantDailyWasteReportLoading, setIsRestaurantDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isLocationDailyWasteReportLoading, setIsLocationDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<undefined | DateObject>(undefined);
  const [isLocationMonthlyReviewLoading, setIsLocationMonthlyReviewLoading] =
    useState<boolean>(false);
  const [isBaselineReviewLoading, setIsBaselineReviewLoading] = useState<boolean>(false);
  const [minStartDate, setMinStartDate] = useState<undefined | DateObject>(undefined);

  /**
   * This function takes in display message to be displayed and sets the error state to the message
   * @param message String - Message to be displayed
   */
  const displayError = (message: string) => {
    setError({
      isVisible: true,
      message,
    });
  };

  /**
   * This function resets the error message back to its initial value
   */
  const resetErrorMessage = () => setError(initialError);

  /**
   * This function sets the isCompanyReportLoading / isRestaurantReportLoading / isLocationReportLoading states to true or false
   * depending on whichever report the user is downloading
   * @param findBy - 'company', 'restaurant', 'location'
   * @param isTrue - boolean to set the states to true or false
   */
  const setIsReportLoading = (findBy: string, isTrue: boolean) => {
    if (findBy === 'company') {
      setIsCompanyReportLoading(isTrue);
    } else if (findBy === 'restaurant') {
      setIsRestaurantReportLoading(isTrue);
    } else if (findBy === 'location') {
      setIsLocationReportLoading(isTrue);
    }
  };

  /**
   * This function handles the generation of csv file for download
   */
  const generateReport = async (findBy: string) => {
    try {
      setIsReportLoading(findBy, true);
      const { companyField, restaurantField, locationField, dateRange } = formState;
      const [fromDate, toDate] = dateRange;
      let id;
      let fileName;
      const companyFieldParsed = JSON.parse(companyField);
      const { isAirline, name: companyName } = companyFieldParsed;
      if (findBy === 'company') {
        const { companyId } = companyFieldParsed;
        id = companyId;
        fileName = `Group_${companyName}_${fromDate}_${toDate}`;
      } else if (findBy === 'restaurant') {
        const { restaurantId, name: restaurantName } = JSON.parse(restaurantField);
        id = restaurantId;
        fileName = `Property_${companyName}_${restaurantName}_${fromDate}_${toDate}`;
      } else if (findBy === 'location') {
        const { name: restaurantName } = JSON.parse(restaurantField);
        const { locationId, name: locationName } = JSON.parse(locationField);
        id = locationId;
        fileName = `Location_${companyName}_${restaurantName}_${locationName}_${fromDate}_${toDate}`;
      }
      const startDate = fromDate.format('YYYY-MM-DD');
      const endDate = toDate.format('YYYY-MM-DD');
      const response = await axios.post('/report-management/fetch-report', {
        isAirline,
        findBy,
        attribute: {
          id,
          startDate,
          endDate,
        },
      });

      const { arrReport } = response.data;

      // If returned arrReport has length 0, display 'no data' error
      if (arrReport.length === 0) {
        displayError(ERROR_MESSAGE.noDataInDateRange);
        setIsReportLoading(findBy, false);
        return;
      }

      const options = {
        filename: fileName,
        quoteStrings: '',
        showLabels: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(arrReport);
      setIsReportLoading(findBy, false);
    } catch (error) {
      setIsReportLoading(findBy, false);
      displayError(ERROR_MESSAGE.fetchReportError);
    }
  };

  /**
   * This function sets the isCompanyDailyWasteReportLoading / isRestaurantDailyWasteReportLoading / isLocationDailyWasteReportLoading states to true or false
   * depending on whichever report the user is downloading
   * @param findBy - 'company', 'restaurant', 'location'
   * @param isTrue - boolean to set the states to true or false
   */
  const setIsDailyWasteReportLoading = (findBy: string, isTrue: boolean) => {
    if (findBy === 'company') {
      setIsCompanyDailyWasteReportLoading(isTrue);
    } else if (findBy === 'restaurant') {
      setIsRestaurantDailyWasteReportLoading(isTrue);
    } else if (findBy === 'location') {
      setIsLocationDailyWasteReportLoading(isTrue);
    }
  };

  /**
   * This function handles the generation of aggregated daily service waste data csv file for download
   */
  const generateDailyWasteReport = async (findBy: string) => {
    try {
      const { companyField, restaurantField, locationField, dateRange } = formState;
      const [fromDate, toDate] = dateRange;
      let id;
      let fileName;
      const companyFieldParsed = JSON.parse(companyField);
      const { isAirline, name: companyName } = companyFieldParsed;
      if (isAirline) {
        setIsAlert(true);
        setAlertHeader('Invalid Group Selected');
        setArrAlertMessage([
          `Only non-airline group can be selected for Daily Waste CSV file download`,
        ]);
        return;
      }
      setIsDailyWasteReportLoading(findBy, true);

      if (findBy === 'company') {
        const { companyId } = companyFieldParsed;
        id = companyId;
        fileName = `Group_${companyName}_${fromDate}_${toDate}_daily_waste_data`;
      } else if (findBy === 'restaurant') {
        const { restaurantId, name: restaurantName } = JSON.parse(restaurantField);
        id = restaurantId;
        fileName = `Property_${companyName}_${restaurantName}_${fromDate}_${toDate}_daily_waste_data`;
      } else if (findBy === 'location') {
        const { name: restaurantName } = JSON.parse(restaurantField);
        const { locationId, name: locationName } = JSON.parse(locationField);
        id = locationId;
        fileName = `Location_${companyName}_${restaurantName}_${locationName}_${fromDate}_${toDate}_daily_waste_data`;
      }
      const startDate = fromDate.format('YYYY-MM-DD');
      const endDate = toDate.format('YYYY-MM-DD');
      const response = await axios.post('/report-management/fetch-daily-waste-report', {
        findBy,
        attribute: {
          id,
          startDate,
          endDate,
        },
      });

      const { arrReport } = response.data;

      // If returned arrReport has length 0, display 'no data' error
      if (arrReport.length === 0) {
        displayError(ERROR_MESSAGE.noDataInDateRange);
        setIsDailyWasteReportLoading(findBy, false);
        return;
      }
      const options = {
        filename: fileName,
        quoteStrings: '',
        showLabels: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(arrReport);
      setIsDailyWasteReportLoading(findBy, false);
    } catch (error) {
      setIsDailyWasteReportLoading(findBy, false);
      displayError(ERROR_MESSAGE.fetchReportError);
    }
  };

  /**
   * This function handles the images' signed url returned for download
   * Each signed url returns an image in 'blob' format which is accepted by the JSZip API
   * The zip file is then generated and downloaded by the user via saveAs
   */
  const downloadImage = async () => {
    const { arrSelectedService, isTagged, dateRange } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    const arrServiceId = arrSelectedService.map((service) => service.serviceId);
    const request = {
      arrServiceId,
      startDate,
      endDate,
      isTagged,
    };
    try {
      setIsImageLoading(true);
      const response = await axios.post('/report-management/fetch-image-signed-url', request);

      const { arrImageSignedUrl, arrDownloadError } = response.data;
      const hasImageSignedUrl = arrImageSignedUrl.length > 0;
      const hasDownloadError = arrDownloadError.length > 0;

      // If returned hasImageSignedUrl and arrDownloadError both has length 0, display 'no image' error
      if (!hasImageSignedUrl && !hasDownloadError) {
        displayError(ERROR_MESSAGE.noImageInDateRange);
        return;
      }

      // If returned hasImageSignedUrl has images to be downloaded, package them into a zip file and ship to user
      if (hasImageSignedUrl) {
        const { companyField, restaurantField, locationField } = formState;
        const zip = new JSZip();
        await Promise.all(
          arrImageSignedUrl.map(async (imageSignedUrl: { name: string; signedUrl: string }) => {
            const imageResponse = await axios.get(imageSignedUrl.signedUrl, {
              responseType: 'blob',
            });
            const image = imageResponse.data;
            zip.file(imageSignedUrl.name, image);
          })
        );
        const zipFile = await zip.generateAsync({ type: 'blob' });
        const fileName = `${JSON.parse(companyField).name}_${JSON.parse(restaurantField).name}_${
          JSON.parse(locationField).name
        }_${arrSelectedService.map((service) => service.name).join('_')}_${
          isTagged === undefined ? 'All' : isTagged === false ? 'Untagged' : 'Tagged'
        }_${fromDate}_${toDate}`;
        saveAs(zipFile, fileName);
      }

      // If returned arrDownloadError has images unable to be downloaded, notify user to notify software team
      // Count of download errors are displayed out of total images
      // If there are a mix of valid images and download errors, valid images are downloaded and users are notified of its count
      if (hasDownloadError) {
        setIsAlert(true);
        setAlertHeader('Invalid Download(s) Exist(s)');
        setArrAlertMessage([
          `Of the service's ${
            arrImageSignedUrl.length + arrDownloadError.length
          } image downloads, ${arrDownloadError.length} had returned errors.`,
          `${
            hasImageSignedUrl
              ? `Remaining ${arrImageSignedUrl.length} images have been downloaded.`
              : ''
          }`,
          'Please screenshot this alert, and notify software team immediately.',
          `Datetime: ${new Date().toLocaleString()}`,
        ]);
      }
    } catch (error) {
      displayError(ERROR_MESSAGE.fetchImageError);
    } finally {
      setIsImageLoading(false);
    }
  };

  /**
   * This function handles the backend request for '/report-management/fetch-graph'
   * Waste images are returned as signed url due to Google App Engine's max 32mb response limit, hence image fetching
   * has to be done in the frontend. Graph images are returned as base64 strings as they are constructed in the backend
   * and are relatively much lower in size (~50kb per graph image).
   * @returns Object.locationData - Waste analysis for the location
   * @returns Object.locationData.overview - Waste analysis at the location level, including the graphs and summary tables
   * @returns Object.locationData.breakdown - Arrray of grouped service analysis
   * @returns Object.fileName - Name of file to be downloaded
   * @returns Object.restaurantService - Restaurant and locationService of graphs fetched for
   */
  const fetchGraph = async () => {
    const {
      dateRange,
      companyField,
      restaurantField,
      locationField,
      arrSelectedService,
      isTagged,
    } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    const companyFieldParsed = JSON.parse(companyField);
    const restaurantFieldParsed = JSON.parse(restaurantField);
    const locationFieldParsed = JSON.parse(locationField);
    const restaurantService = arrRestaurantService[Number(restaurantFieldParsed.index)];
    const locationService = restaurantService.arrLocationService[Number(locationFieldParsed.index)];
    const restaurantServiceToFetchGraph = {
      ...restaurantService,
      arrLocationService: [
        {
          ...locationService,
          arrService: arrSelectedService,
        },
      ],
    };
    const request = {
      companyName: companyFieldParsed.name,
      restaurantService: restaurantServiceToFetchGraph,
      startDate,
      endDate,
      isTagged,
    };
    const response = await axios.post('/report-management/fetch-graph', request);
    return {
      ...response.data,
      fileName: `${restaurantFieldParsed.name}_${locationFieldParsed.name}_${fromDate}_${toDate}`,
      restaurantService: restaurantServiceToFetchGraph,
    };
  };

  /**
   * This function handles the backend request for '/report-management/fetch-baseline-graph'
   * Waste images are returned as signed url due to Google App Engine's max 32mb response limit, hence image fetching
   * has to be done in the frontend. Graph images are returned as base64 strings as they are constructed in the backend
   * and are relatively much lower in size (~50kb per graph image).
   * @returns Object.baselineReportData - Object containing key: locationName, value: locationData
   * @returns Object.fileName - Name of file to be downloaded
   * @returns Object.restaurantService - Restaurant, location and arrService of graphs fetched for
   */
  const fetchBaselineGraph = async () => {
    const {
      dateRange,
      companyField,
      restaurantField,
      locationField,
      arrSelectedService,
      isTagged,
    } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    const companyFieldParsed = JSON.parse(companyField);
    const restaurantFieldParsed = JSON.parse(restaurantField);
    const locationFieldParsed = JSON.parse(locationField);
    const restaurantService = arrRestaurantService[Number(restaurantFieldParsed.index)];
    const locationService = restaurantService.arrLocationService[Number(locationFieldParsed.index)];
    const restaurantServiceToFetchBaselineGraph = {
      ...restaurantService,
      arrLocationService: [
        {
          ...locationService,
          arrService: arrSelectedService,
        },
      ],
    };
    const request = {
      companyName: companyFieldParsed.name,
      restaurantService: restaurantServiceToFetchBaselineGraph,
      startDate,
      endDate,
      isTagged,
    };
    const response = await axios.post('/report-management/fetch-baseline-graph', request);
    return {
      ...response.data,
      fileName: `Baseline_Report_For_${restaurantFieldParsed.name}_${locationFieldParsed.name}_${fromDate}_${toDate}`,
      restaurantService: restaurantServiceToFetchBaselineGraph,
    };
  };

  /**
   * This function is invoked on change of Company select element to update formState and the restaurantService
   * selection(s)
   * @param event - Change event provided by the select element, provides companyId, companyName, isAirline
   */
  const updateCompanyFormState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedArrRestaurantService = companyContext.arrCompanyService.find(
      (companyService: CompanyService) =>
        companyService.companyId === JSON.parse(event.target.value).companyId
    ).arrRestaurantService;
    setArrRestaurantService(selectedArrRestaurantService);
    setFormState({
      ...formState,
      companyField: event.target.value,
      restaurantField: 'Select Property',
      locationField: 'Select Location',
      arrSelectedService: [],
    });
    setMinDate(undefined);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change date range picker to update formState
   * @param dateRange - Array containing from and to DateObjects
   */
  const updateDateRangeFormState = (dateRange: Array<DateObject>) => {
    setFormState({ ...formState, dateRange });
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of Restaurant select element to update formState
   * @param event - Change event providing restaurantName, restaurant's index
   */
  const updateRestaurantFormState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormState({
      ...formState,
      restaurantField: event.target.value,
      locationField: 'Select Location',
      arrSelectedService: [],
    });
    setMinDate(undefined);
    resetErrorMessage();
  };

  /**
   * This function retrieves the selected location's earliest project and baseline start date among its valid services
   * @param arrService - Array of service
   * @returns locationEarliestStartDate - Location's earliest baseline start date among its valid services
   * @returns locationEarliestProjectStartDate - Location's earliest project start date among its valid services
   */
  const getLocationEarliestStartDateAndProjectStartDate = (
    arrService: Array<Service>
  ): {
    locationEarliestStartDate: string | null;
    locationEarliestProjectStartDate: string | null;
  } => {
    let locationEarliestStartDate: string | null = null;
    let locationEarliestProjectStartDate: string | null = null;
    arrService.forEach((service) => {
      const { projectStartDate, isValid, startDate } = service;
      if (isValid) {
        if (locationEarliestProjectStartDate === null) {
          locationEarliestProjectStartDate = projectStartDate;
        } else if (projectStartDate < locationEarliestProjectStartDate) {
          locationEarliestProjectStartDate = projectStartDate;
        }
      }
      if (startDate !== null) {
        if (locationEarliestStartDate === null) {
          locationEarliestStartDate = startDate;
        } else if (startDate < locationEarliestStartDate) {
          locationEarliestStartDate = startDate;
        }
      }
    });
    return { locationEarliestStartDate, locationEarliestProjectStartDate };
  };

  /**
   * This function is invoked on change of Location select element to update formState
   * By selecting a location, the date picker will also implement a min date selectable by the user according to
   * the location's earliest project start date.
   * If the currently selected date range falls before partially or fully of the location's earliest project start date,
   * then the selected 'from' date will be updated to the location's earliest project start date.
   * @param event - Change event providing locationName, location's index
   */
  const updateLocationFormState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Retrieve arrService in order to get location's earliest project start date
    const { restaurantField } = formState;
    const restaurantIndex = Number(JSON.parse(restaurantField).index);
    const locationIndex = Number(JSON.parse(event.target.value).index);
    const { arrService } = arrRestaurantService[restaurantIndex].arrLocationService[locationIndex];
    const { locationEarliestProjectStartDate, locationEarliestStartDate } =
      getLocationEarliestStartDateAndProjectStartDate(arrService);
    const updatedFormState = {
      ...formState,
      locationField: event.target.value,
      arrSelectedService: arrService.filter(
        (service) =>
          service.isValid && service.startDate && service.serviceType.type !== 'Placeholder'
      ),
    };

    if (locationEarliestProjectStartDate !== null) {
      const locationEarliestProjectStartDateInDateObjectFormat = new DateObject(
        locationEarliestProjectStartDate
      );
      setMinDate(locationEarliestProjectStartDateInDateObjectFormat);
      // If min date is later than currently selected from date, then set from date as min date
      if (locationEarliestProjectStartDate > formState.dateRange[0].format('YYYY-MM-DD')) {
        updatedFormState.dateRange = [
          locationEarliestProjectStartDateInDateObjectFormat,
          formState.dateRange[1],
        ];
      }
      // If min date is later than currently selected to date, then set to date as min date
      if (locationEarliestProjectStartDate > formState.dateRange[1].format('YYYY-MM-DD')) {
        updatedFormState.dateRange[1] = locationEarliestProjectStartDateInDateObjectFormat;
      }
    } else {
      setMinDate(undefined);
    }
    setMinStartDate(
      locationEarliestStartDate === null ? undefined : new DateObject(locationEarliestStartDate)
    );
    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function is invoked on clicking a selection of company, restaurant and location on the SearchBar component.
   * With the selection of a location, the date picker will also implement a min date selectable by the user according to
   * the location's earliest project start date.
   * If the currently selected date range falls before partially or fully of the location's earliest project start date,
   * then the selected 'from' date will be updated to the location's earliest project start date.
   * @param event - Mouse event
   * @param selectedOption - Selected companyLocation option providing companyField, restaurantField and locationField information
   */
  const updateCompanyLocationFormState = (
    event: React.MouseEvent<HTMLLIElement>,
    selectedOption: CompanyLocationOption
  ) => {
    const {
      companyId,
      companyName,
      isAirline,
      restaurantId,
      restaurantName,
      restaurantIndex,
      locationId,
      locationName,
      locationIndex,
    } = selectedOption;
    const selectedArrRestaurantService = companyContext.arrCompanyService.find(
      (companyService: CompanyService) => companyService.companyId === companyId
    ).arrRestaurantService;

    const selectedCompanyField = `{"companyId": ${companyId}, "name": "${companyName}", "isAirline": ${isAirline}}`;
    const selectedRestaurantField = `{"name": "${restaurantName}", "restaurantId": ${restaurantId}, "index": ${restaurantIndex}}`;
    const selectedLocationField = `{"name": "${locationName}", "locationId": ${locationId}, "index": ${locationIndex}}`;

    // Retrieve arrService in order to get location's earliest project start date
    const { arrService } =
      selectedArrRestaurantService[restaurantIndex].arrLocationService[locationIndex];
    const { locationEarliestStartDate, locationEarliestProjectStartDate } =
      getLocationEarliestStartDateAndProjectStartDate(arrService);
    const updatedFormState = {
      ...formState,
      companyField: selectedCompanyField,
      restaurantField: selectedRestaurantField,
      locationField: selectedLocationField,
      arrSelectedService: arrService.filter(
        (service: Service) =>
          service.isValid && service.startDate && service.serviceType.type !== 'Placeholder'
      ),
    };

    if (locationEarliestProjectStartDate !== null) {
      const locationEarliestProjectStartDateInDateObjectFormat = new DateObject(
        locationEarliestProjectStartDate
      );
      setMinDate(locationEarliestProjectStartDateInDateObjectFormat);
      // If min date is later than currently selected from date, then set from date as min date
      if (locationEarliestProjectStartDate > formState.dateRange[0].format('YYYY-MM-DD')) {
        updatedFormState.dateRange = [
          locationEarliestProjectStartDateInDateObjectFormat,
          formState.dateRange[1],
        ];
      }
      // If min date is later than currently selected to date, then set to date as min date
      if (locationEarliestProjectStartDate > formState.dateRange[1].format('YYYY-MM-DD')) {
        updatedFormState.dateRange[1] = locationEarliestProjectStartDateInDateObjectFormat;
      }
    } else {
      setMinDate(undefined);
    }
    setMinStartDate(
      locationEarliestStartDate === null ? undefined : new DateObject(locationEarliestStartDate)
    );
    setArrRestaurantService(selectedArrRestaurantService);
    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of Service checkbox element to update formState
   * @param event - Change event providing serviceName, serviceId
   */
  const updateServiceFormState = (isChecked: boolean, serviceSelected: Service) => {
    if (isChecked) {
      const arrUpdatedServiceSelected = [...formState.arrSelectedService];
      arrUpdatedServiceSelected.push(serviceSelected);
      setFormState({
        ...formState,
        arrSelectedService: arrUpdatedServiceSelected,
      });
    } else {
      setFormState({
        ...formState,
        arrSelectedService: formState.arrSelectedService.filter(
          (service) => service !== serviceSelected
        ),
      });
    }
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of isTagged radio element to update formState
   * @param isTagged - undefined (all), 'allExceptUntagged' (allExceptUntagged), 'taggedAndResolvable' (taggedAndResolvable), true (taggedAndMapped) or false (untagged)
   */
  const updateIsTaggedFormState = (isTagged: boolean | string | undefined) => {
    setFormState({ ...formState, isTagged });
  };

  /**
   * This function is called when "Select location's earliest baseline start date" button is clicked to assist
   * users in selecting the earliest start date of services of location selected to the last day of the
   * previous month.
   * In order for this function to be invoked, a location must have been selected before which populates
   * the minDate state, which is the earliest start date of services of location selected.
   * If all location's services have null startDate (minDate remains as undefined), an alert will appear
   * prompting user to add startDate
   * @returns DateObject[] - Array of DateObject
   * @returns DateObject[0] - Starting date of date range
   * @returns DateObject[1] - Ending date of date range
   */
  const selectLocationEarliestStartDate = (): Array<DateObject> | undefined => {
    if (minStartDate !== undefined) {
      return [new DateObject(minStartDate), lastDateOfPrevMonth];
    } else {
      setIsAlert(true);
      setAlertHeader('No service start date found!');
      setArrAlertMessage([
        'To use this feature, please ensure start date for services under this location has been added.',
      ]);
    }
  };

  /**
   * This function is called when "Select location's earliest project start date" button is clicked to assist
   * users in selecting the earliest project start date of services of location selected to the last day of the
   * previous month.
   * In order for this function to be invoked, a location must have been selected before which populates
   * the minDate state, which is the earliest project start date of services of location selected.
   * If all location's services are not valid (minDate remains as undefined), an alert will appear
   * prompting user to ensure at least one service is valid
   * @returns DateObject[] - Array of DateObject
   * @returns DateObject[0] - Starting date of date range
   * @returns DateObject[1] - Ending date of date range
   */
  const selectLocationEarliestProjectStartDate = (): Array<DateObject> | undefined => {
    if (minDate !== undefined) {
      return [new DateObject(minDate), lastDateOfPrevMonth];
    } else {
      setIsAlert(true);
      setAlertHeader('No valid service found!');
      setArrAlertMessage([
        'To use this feature, please ensure services under this location are valid.',
      ]);
    }
  };

  /**
   * This function is called when user clicks on any of the date range quick select buttons:
   * 1. Last Week
   * 2. Last Month
   * 3. Last 3 Months
   * 4. From Location's Earliest Start Date
   * 5. From Location's Earliest Project Start Date
   * @param dateRangeOption - Type of quick select the user clicks
   */
  const quickSelectDateRange = (dateRangeOption: string) => {
    let dateRange: Array<DateObject> | undefined;
    const todayMoment = moment();
    switch (dateRangeOption) {
      case QUICK_SELECT_OPTION.lastWeek:
        dateRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.lastMonth:
        dateRange = [firstDateOfPrevMonth, lastDateOfPrevMonth];
        break;
      case QUICK_SELECT_OPTION.last3Months:
        dateRange = [
          new DateObject(
            todayMoment.clone().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.fromLocationEarliestStartDate:
        dateRange = selectLocationEarliestStartDate();
        break;
      case QUICK_SELECT_OPTION.fromLocationEarliestProjectStartDate:
        dateRange = selectLocationEarliestProjectStartDate();
        break;
      default:
        return;
    }
    // If condition necessary as 'fromLocationEarliestProjectStartDate' may return undefined if all location's
    // services have null startDate.
    if (dateRange) {
      setFormState({
        ...formState,
        dateRange,
      });
    }
  };

  /**
   * This function resets the formState to its initial values
   */
  const resetForm = () => {
    setFormState(initialFormState);
    setArrRestaurantService([]);
    resetErrorMessage();
    setIsCompanyReportLoading(false);
    setIsRestaurantReportLoading(false);
    setIsLocationReportLoading(false);
    setIsImageLoading(false);
    setMinDate(undefined);
  };

  /**
   * This function handles the generation of powerpoint slides and initiates download for the client browser
   * The function reuses the fetchGraph() function used for fetching location graph images
   */
  const downloadLocationMonthlyReview = async () => {
    try {
      setIsLocationMonthlyReviewLoading(true);
      const fetchGraphRes = await fetchGraph();
      await downloadLocationMonthlyReviewPowerpoint(fetchGraphRes, formState, user);
    } catch (error: any) {
      if (error?.response?.data.error === CALCULATOR_CONNECTION_ERROR) {
        displayError(ERROR_MESSAGE.fetchLocationMonthlyReviewWithCalculatorConnectionError);
      } else {
        displayError(ERROR_MESSAGE.fetchLocationMonthlyReviewError);
      }
    } finally {
      setIsLocationMonthlyReviewLoading(false);
    }
  };

  /**
   * This function handles the generation of powerpoint slides for baseline report and initiates download for the client browser
   */
  const downloadBaselineReview = async () => {
    try {
      setIsBaselineReviewLoading(true);
      const fetchBaselineGraphRes = await fetchBaselineGraph();
      await downloadBaselineReviewPowerpoint(fetchBaselineGraphRes, formState, user);
    } catch (error) {
      console.log(error);
      displayError(ERROR_MESSAGE.fetchBaselineReviewError);
    } finally {
      setIsBaselineReviewLoading(false);
    }
  };

  const renderServiceCheckbox = (arrValidService: Array<Service>) => {
    if (arrValidService.length === 0) {
      return (
        <div className="text-sm font-medium col-span-3 text-red-500 ">
          No valid services for selected location
        </div>
      );
    }
    return arrValidService.map((service: Service, index: number) => (
      <div key={`ServiceCheckbox ${service.serviceId}`} className="flex items-center">
        <Checkbox
          defaultChecked
          id={`ServiceCheckbox ${service.serviceId}`}
          onChange={(e) => {
            updateServiceFormState(e.target.checked, service);
          }}
          icon={<MdCheck className="h-3 w-3" />}
        />
        <p className="text-base font-seminold break-words align-middle">{service.name}</p>
      </div>
    ));
  };

  return (
    <div className="p-5 pt-3 flex flex-col">
      {isAlert && (
        <AppAlert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <h1 className="font-bold text-[#152445] text-3xl inline-block align-text-bottom">Reports</h1>
      <div className="grow flex flex-wrap self-center justify-end w-5/6 mb-3">
        <SearchBar updateFunction={updateCompanyLocationFormState} />
      </div>
      <div className="grid grid-cols-1 gap-5 w-5/6 border-2 rounded-xl px-5 py-6 mt-1 self-center">
        <div className="flex flex-col lg:flex-row gap-5 lg:gap-0">
          <div className="flex flex-col flex-1 gap-5 px-5">
            <div className="text-xl font-semibold">
              Group:
              <select
                name="groups"
                className="GroupField mt-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer"
                value={formState.companyField}
                onChange={(event) => {
                  updateCompanyFormState(event);
                }}
              >
                <option disabled defaultChecked>
                  Select Group
                </option>
                {companyContext.arrCompanyService.map(
                  (companyService: CompanyService, index: number) => (
                    <option
                      key={index}
                      value={`{"companyId": ${companyService.companyId}, "name": "${companyService.name}", "isAirline": ${companyService.isAirline}}`}
                    >
                      {companyService.name}
                      {companyService.isAirline && ' ✈️'}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="text-xl font-semibold flex flex-col">
              Date Range:
              <DatePicker
                range
                rangeHover
                value={formState.dateRange}
                onChange={updateDateRangeFormState}
                weekDays={WEEKDAYS}
                numberOfMonths={2}
                weekStartDayIndex={1}
                minDate={minDate}
                maxDate={new DateObject()}
                format="DD/MM/YYYY"
                showOtherDays
                portal
                render={
                  <InputIcon className="DateRangePicker mt-2 text-base block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer" />
                }
              />
            </div>
            <div className="text-xl font-semibold flex">
              <Button
                color="teal"
                className="LastWeekButton flex-1 px-1 rounded-r-none border-r"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.lastWeek)}
              >
                Last Week
              </Button>
              <Button
                color="teal"
                className="LastMonthButton flex-1 px-1 rounded-none border-x"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.lastMonth)}
              >
                Last Month
              </Button>
              <Button
                color="teal"
                className="Last3MonthsButton flex-1 px-1 rounded-none border-x"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.last3Months)}
              >
                Last 3 Months
              </Button>
              <Button
                color="teal"
                className="FromLocationEarliestProjectStartDateButton min-w-min text-[10px] flex-1 leading-tight px-1 rounded-none border-x py-2"
                disabled={formState.locationField === 'Select Location'}
                onClick={() =>
                  quickSelectDateRange(QUICK_SELECT_OPTION.fromLocationEarliestProjectStartDate)
                }
              >
                Location's earliest project start date to end of last month
              </Button>
              <Button
                color="teal"
                className="FromLocationEarliestStartDateButton min-w-min text-[10px] flex-1 leading-tight px-1 rounded-l-none border-l py-2"
                disabled={formState.locationField === 'Select Location'}
                onClick={() =>
                  quickSelectDateRange(QUICK_SELECT_OPTION.fromLocationEarliestStartDate)
                }
              >
                Location's earliest baseline start date to end of last month
              </Button>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2 px-5 lg:border-l-2">
            <div
              className={`text-xl font-semibold flex-1 ${
                formState.companyField === 'Select Group' && 'opacity-30'
              }`}
            >
              Property:
              <select
                name="properties"
                className="PropertyField mt-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer disabled:opacity-30 disabled:cursor-default"
                value={formState.restaurantField}
                disabled={formState.companyField === 'Select Group'}
                onChange={updateRestaurantFormState}
              >
                <option disabled defaultChecked>
                  Select Property
                </option>
                {formState.companyField !== 'Select Group' &&
                  arrRestaurantService.map((restaurant: RestaurantService, index: number) => (
                    <option
                      key={index}
                      value={`{"name": "${restaurant.name}", "restaurantId": ${restaurant.restaurantId}, "index": ${index}}`}
                    >
                      {restaurant.name}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className={`text-xl font-semibold flex-1 ${
                formState.restaurantField === 'Select Property' && 'opacity-30'
              }`}
            >
              Location:
              <select
                name="locations"
                className="LocationField mt-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer disabled:opacity-30 disabled:cursor-default"
                value={formState.locationField}
                disabled={formState.restaurantField === 'Select Property'}
                onChange={updateLocationFormState}
              >
                <option disabled defaultChecked>
                  Select Location
                </option>
                {formState.restaurantField !== 'Select Property' &&
                  arrRestaurantService[
                    Number(JSON.parse(formState.restaurantField).index)
                  ].arrLocationService.map((location: LocationService, index: number) => (
                    <option
                      key={index}
                      value={`{"name": "${location.name}", "locationId": ${location.locationId}, "index": ${index}}`}
                    >
                      {location.name}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className={`text-xl font-semibold flex-1 ${
                formState.locationField === 'Select Location' && 'opacity-30'
              }`}
            >
              Service:
              <div className="grid grid-cols-3 gap-2 min-h-[2.8rem] items-center">
                {formState.locationField !== 'Select Location' ? (
                  renderServiceCheckbox(
                    arrRestaurantService[
                      Number(JSON.parse(formState.restaurantField).index)
                    ].arrLocationService[
                      Number(JSON.parse(formState.locationField).index)
                    ].arrService.filter(
                      (service) =>
                        service.isValid &&
                        service.startDate &&
                        service.serviceType.type !== 'Placeholder'
                    )
                  )
                ) : (
                  <div className="text-base">Select Service</div>
                )}
              </div>
            </div>

            <div className="text-xl font-semibold flex-1">
              Data Type:
              <div className="flex mt-2">
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="inline-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.taggedAndResolvable}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.taggedAndResolvable)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Tagged And Resolvable
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="inline-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.taggedAndMapped}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.taggedAndMapped)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Tagged And Mapped
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="inline-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.allExceptUntagged}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.allExceptUntagged)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    All Except Untagged
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="inline-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.all}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.all)}
                  />
                  <label
                    htmlFor="inline-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    All
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="inline-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.untagged}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.untagged)}
                  />
                  <label
                    htmlFor="inline-checked-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Untagged
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {!error.isVisible && (
            <div className="flex gap-2 flex-col lg:flex-row">
              <div className="flex-1 flex">
                <Button
                  className="GroupReportButton bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    isCompanyReportLoading ||
                    formState.dateRange.length !== 2
                  }
                  onClick={() => generateReport('company')}
                >
                  {isCompanyReportLoading ? <LoadingSpinner /> : 'Group Report (.csv)'}
                </Button>
                <Button
                  className="PropertyReportButton bg-regal-blue p-3 flex-1 rounded-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.dateRange.length !== 2 ||
                    isRestaurantReportLoading
                  }
                  onClick={() => generateReport('restaurant')}
                >
                  {isRestaurantReportLoading ? <LoadingSpinner /> : 'Property Report (.csv)'}
                </Button>
                <Button
                  className="LocationReportButton bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.locationField === 'Select Location' ||
                    formState.dateRange.length !== 2 ||
                    isLocationReportLoading
                  }
                  onClick={() => generateReport('location')}
                >
                  {isLocationReportLoading ? <LoadingSpinner /> : 'Location Report (.csv)'}
                </Button>
              </div>
              <div className="flex-1 flex">
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    isCompanyDailyWasteReportLoading ||
                    formState.dateRange.length !== 2
                  }
                  onClick={() => generateDailyWasteReport('company')}
                >
                  {isCompanyDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Company Daily Waste Report (.csv)'
                  )}
                </Button>
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.dateRange.length !== 2 ||
                    isRestaurantDailyWasteReportLoading
                  }
                  onClick={() => generateDailyWasteReport('restaurant')}
                >
                  {isRestaurantDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Restaurant Daily Waste Report (.csv)'
                  )}
                </Button>
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.locationField === 'Select Location' ||
                    formState.dateRange.length !== 2 ||
                    isLocationDailyWasteReportLoading
                  }
                  onClick={() => generateDailyWasteReport('location')}
                >
                  {isLocationDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Location Daily Waste Report (.csv)'
                  )}
                </Button>
              </div>
              <div className="flex-1 flex">
                <Button
                  className="LocationMonthlyReviewButton bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.locationField === 'Select Location' ||
                    formState.arrSelectedService.length === 0 ||
                    formState.dateRange.length !== 2 ||
                    formState.isTagged === IS_TAGGED_VALUES.all ||
                    formState.isTagged === IS_TAGGED_VALUES.untagged ||
                    isBaselineReviewLoading
                  }
                  onClick={downloadBaselineReview}
                >
                  {isBaselineReviewLoading ? <LoadingSpinner /> : 'Baseline Review (.pptx)'}
                </Button>
                <Button
                  className="LocationMonthlyReviewButton bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.locationField === 'Select Location' ||
                    formState.arrSelectedService.length === 0 ||
                    formState.dateRange.length !== 2 ||
                    formState.isTagged === IS_TAGGED_VALUES.all ||
                    formState.isTagged === IS_TAGGED_VALUES.untagged ||
                    isLocationMonthlyReviewLoading
                  }
                  onClick={downloadLocationMonthlyReview}
                >
                  {isLocationMonthlyReviewLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Location Monthly Review (.pptx)'
                  )}
                </Button>
              </div>
              <Button
                className="ServiceImageButton bg-regal-blue p-3 flex-1"
                disabled={
                  formState.companyField === 'Select Group' ||
                  formState.restaurantField === 'Select Property' ||
                  formState.locationField === 'Select Location' ||
                  (formState.arrSelectedService && formState.arrSelectedService.length === 0) ||
                  formState.dateRange.length !== 2 ||
                  isImageLoading
                }
                onClick={downloadImage}
              >
                {isImageLoading ? <LoadingSpinner /> : 'Service Images (.zip)'}
              </Button>
            </div>
          )}
          {error.isVisible && (
            <Alert
              color="red"
              variant="filled"
              dismissible={{
                onClose: resetErrorMessage,
              }}
            >
              {error.message}
            </Alert>
          )}
          <Button color="amber" className="mt-3" onClick={resetForm}>
            Reset Fields
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportInputCard;
